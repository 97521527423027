import { Link } from "react-scroll";
import ReactTypingEffect from "react-typing-effect";
import SlideIn from "../../utils/SlideIn";
/*--------------------
* Banner Section
----------------------*/
export default function Banner() {
  const subject = "Tutoring";
  const body =
    "Hello,\n I would like to discuss potential tutoring for my child.";
  const mailto = `mailto:georgeterzian2014@gmail.com?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  return (
    <>
      <section
        data-scroll-data="0"
        id="home"
        className="home-section pt-[120px]  lg:pt-[250px] pb-[340px] bg-zinc-900/95 fixed top-0 left-0 w-full z-0 overflow-hidden"
      >
        <SlideIn>
          <div className="container relative z-[1]">
            <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
              <div className="lg:col-span-8">
                <div className="text-center lg:text-start mb-[50px] lg:mb-0">
                  <h6 className="text-slate-200 uppercase text-[14px] md:text-[16px] tracking-[2px] font-[600] mb-[20px] md:mb-[30px]">
                    👋 My name is George Terzian
                  </h6>
                  <h1 className="text-white font-[600] text-[40px] lg:text-[60px] xl:text-[70px] leading-[1] mb-[25px] md:mb-[35px]">
                    I'm a{" "}
                    <span id="type-it">
                      <ReactTypingEffect
                        text={["Math", "Science"]}
                        speed="100"
                        typingDelay="500"
                        eraseDelay="1000"
                      />
                    </span>{" "}
                    Tutor
                  </h1>
                  <p className="text-[16px] md:text-[20px] mb-[8px] md:mb-[16px] text-slate-200">
                    Based in Los Angeles, California.
                  </p>
                  <div className="pt-[10px]">
                    <a href={mailto} className="px-btn px-btn-theme" >
                      Let's Start
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SlideIn>
      </section>
    </>
  );
}
