import Reveal from "../../utils/Reveal";
import SlideIn from "../../utils/Reveal";

/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5 class="text-white">{props.title}</h5>
            <p class="text-slate-200">{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: { main: "bg-lime-900	", icon: "fas fa-calculator" },
      title: "Math Tutoring",
      description:
        "Simplify and conquer math challenges with personalized guidance that makes complex concepts easy and enjoyable.",
    },
    {
      id: { main: "bg-[#6F4E37]	", icon: "fas fa-flask" },
      title: "Science Tutoring",
      description:
        "Ignite curiosity and explore the wonders of science through interactive and engaging lessons tailored to your interests.",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gradient-to-r from-zinc-900 to-zinc-200 relative  z-10"
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>My Services</span>
              </h3>
            </div>
          </div>

          <Reveal>
            <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
              {service_list.map((val, i) => {
                return (
                  <Service
                    key={i}
                    id={val.id}
                    title={val.title}
                    description={val.description}
                  />
                );
              })}
            </div>
          </Reveal>
        </div>
      </section>
    </>
  );
}
