'use client'

import React from 'react';
import { motion } from 'framer-motion';

const SlideIn = ({ children }) => {
    const variants = {
        hidden: { x: '-100vw' }, // Start off-screen to the left
        visible: { 
            x: 0, // End at its natural position
            transition: {
                type: 'spring', // Smooth spring animation
                stiffness: 40, // Spring stiffness
                damping: 10, // Spring damping
                duration: 2, // Increased duration for a slower animation

            },
        },
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
        >
            {children}
        </motion.div>
    );
};

export default SlideIn;
