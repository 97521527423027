import { Link } from "react-scroll";
import Reveal from "../../utils/Reveal";
/*--------------------
* About Section
----------------------*/
export default function About() {
  const subject = "Tutoring";
  const body =
    "Hello,\n I would like to discuss potential tutoring for my child.";
  const mailto = `mailto:georgeterzian2014@gmail.com?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  return (
    <>
      <section className="section relative bg-white z-10 mt-[100vh]">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto scale-75 w-full max-w-screen-lg border-2 border-gray-300 shadow-lg rounded-lg hover:scale-90 transition-transform duration-300"
                src="img/George-2.jpg"
                title="Banner"
                alt="Banner"
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                I'm a High-School and Middle School Tutor with over 3 years of
                experience.
              </h3>
              <p className="text-[16px] md:text-[18px]">
                I'm an experienced math and science tutor with a background in
                biology, passionate about teaching students of all ages. I
                believe in personalized, caring tutoring that adapts to each
                student's unique needs, ensuring their academic growth and
                success.
              </p>

              <div className="space-y-4">
                <h5 className="font-bold text-xl mb-8 mt-12 text-gray-800">
                  Let's Connect
                </h5>
              </div>

              <div className="">
                <div className="">
                  <ul className="contact-infos">
                    <li>
                      <div className="icon bg-yellow-400">
                        <i className="fab fa-linkedin-in"></i>
                      </div>
                      <div className="col">
                        <h5>LinkedIn</h5>
                        <a
                          href="https://www.linkedin.com/in/george-terzian-27907216a"
                          target="_blank"
                        >
                          <button>
                            <p className="hover:text-zinc-400 hover:border-b border-zinc-400">
                              Check out my LinkedIn{" "}
                            </p>
                          </button>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="icon bg-yellow-400">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="col">
                        <h5>Email</h5>
                        <a href={mailto}>
                          <button>
                            <p className="hover:text-zinc-400 hover:border-b border-zinc-400">
                              Send Email{" "}
                            </p>
                          </button>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
