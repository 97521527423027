export default function Interested() {
  return (
    <section className="py-10 bg-zinc-900 relative z-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 items-center">
          <div className="md:col-span-8 lg:col-span-7">
            <h4 className="text-2xl md:text-3xl lg:text-4xl text-white font-semibold">
              Interested in working with me?
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
}
