import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Reveal({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,  // The entire element must be visible
    rootMargin: '-50px 0px 0px 0px', // Adjust this value as needed
  });

  useEffect(() => {
    function handleResize() {
      // Update animation controls based on window width
      if (window.innerWidth >= 768) {
        // For md and larger screens, allow animation
        if (inView) {
          controls.start("visible");
        }
      } else {
        // For smaller screens, skip the animation
        controls.start("visible"); // Immediately set to visible without animation
      }
    }

    // Call once to set initial state based on current window size
    handleResize();

    // Add event listener to adjust on resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [controls, inView]); // Include controls and inView in dependency array to ensure updated values are used

  return (
    <div ref={ref} className="relative w-full overflow-hidden">
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Reveal;
