/*--------------------
* Skill Section
----------------------*/

import Reveal from "../../utils/Reveal";

function Skill(props) {
  return (
    <>
      <div
        className={
          props.id.main +
          " flex items-center justify-center col-span-6 feature-box-02"
        }
      >
        <div className="flex flex-col justify-center items-center ">
          <div className=" pb-2 icon">
            <i className={props.id?.icon}></i>
          </div>
          <h6 class="text-xl ">{props.title}</h6>

          <p class="text-md text-zinc-500 text-center">{props.description} </p>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Skill List Section
----------------------*/
export default function SkillList() {
  const skill_list = [
    {
      id: { main: "", icon: "fas fa-calculator" },
      title: "MATH",
      description:
        "Empowering students to unravel complex mathematical puzzles with ease and confidence.",
    },
    {
      id: { main: "", icon: "fas fa-flask" },
      title: "SCIENCE",
      description:
        "Fostering a deep curiosity and understanding of the natural world through scientific principles.",
    },
    {
      id: { main: "", icon: "fas fa-phone-volume" },
      title: "COMMUNICATION",
      description:
        "Enhancing students' ability to clearly express ideas and comprehend mathematical and scientific concepts.",
    },
    {
      id: { main: "", icon: "fas fa-calendar-minus" },
      title: "ORGANIZED",
      description:
        "Instilling structured and methodical approaches to studying and problem-solving.",
    },
    {
      id: { main: "", icon: "fas fa-bullhorn" },
      title: "MOTIVATIONAL",
      description:
        "Inspiring and encouraging students to reach their full academic potential.",
    },
    {
      id: { main: "", icon: "fas fa-heart" },
      title: "EMPATHETIC",
      description:
        "Understanding and adapting to each student's unique learning needs and challenges.",
    },
  ];

  return (
    <>
      <section
        data-scroll-data="2"
        id="skill"
        className="section experience-section !pb-0 relative z-10 bg-white"
      >
        <div className="container flex items-center justify-center ">
          <div className="grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1">
            <div className="lg:col-span-6 lg:pl-9 md:col-span-2 mb-8">
              <div className="section-heading flex items-center justify-center">
                <h3 className="m-0 ">
                  <span>My Skills</span>
                </h3>
              </div>
              <Reveal>
                <div className="skill-box mb-20">
                  <div className="grid gap-4 grid-cols-12">
                    {skill_list.map((val, i) => {
                      return (
                        <Skill
                          key={i}
                          id={val.id}
                          title={val.title}
                          description={val.description}
                        />
                      );
                    })}
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
