import { Link } from "react-scroll";
import CalendlyWidget from "../../utils/Calendly";
/*--------------------
* Contact Section
----------------------*/

export default function Contact() {
  const subject = "Tutoring";
  const body =
    "Hello,\n I would like to discuss potential tutoring for my child.";
  const mailto = `mailto:georgeterzian2014@gmail.com?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-zinc-900 relative z-10"
      >
        <div className="container flex flex-col items-center justify-center">
          <p className="text-gray-400 text-xl font-bold">
            For Existing Clients, Schedule a Session
          </p>
          <p className="text-gray-400/70">
            For new clients contact me{" "}
            <a href={mailto} className="hover:text-white hover:border-b border-zinc-400 underline text-zinc-400">
             
             Here
            </a>
            .
          </p>
          <CalendlyWidget />
        </div>
      </section>
    </>
  );
}
